import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const MixBarLines: React.FC = () => {
  useEffect(() => {
    const chartDom = document.getElementById('mixBarLines')!;
    const myChart = echarts.init(chartDom);
    const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');
    const colors = ['#5470C6', '#91CC75', '#EE6666'];
    const option = {
      color: colors,
      title: {
        top: 10,
        left: 10,
        text: 'Monthly shipments',
        color: isDarkMode ? '#FF0000' : '#000000'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      }, 
      legend: {
        data: ['Created', 'In transit', 'Delivered'], 
        top: 'auto',
        left: 10,
        bottom : 10,
        orient: 'horizontal',
      }, 
      xAxis: [
        {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        }
      ],
      yAxis: [
        {  }
      ],
      series: [
        {
          name: 'Created',
          type: 'bar',
          data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3]
        },
        {
          name: 'In transit',
          type: 'bar', 
          data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3]
        },
        {
          name: 'Delivered',
          type: 'line', 
          data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
        }
      ]
    };

    myChart.setOption(option);

    // Cleanup function to dispose of the chart instance when the component unmounts
    return () => {
      myChart.dispose();
    };
  }, []);

  return <div id="mixBarLines" style={{ width: 'auto', height: '400px' }}></div>;
};

export default MixBarLines;