import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Descriptions, Checkbox, Spin, Alert, Result, Button, notification, Input, Form, Select, Row, Col, TimePicker } from 'antd';
import axios from 'axios';
import hasPermission from '../../Hooks/hasPermission';

const { Option } = Select;

const DeliveryDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const url = process.env.REACT_APP_API_URL;
    const user = JSON.parse(localStorage.getItem('users') || '[]')[0];
    const canClients = hasPermission(user?.permissionsActives, 'CLIENTS');
    const canUser = hasPermission(user?.permissionsActives, 'USER');
    const [api, contextHolder] = notification.useNotification();
    const [delivery, setDelivery] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [clientsList, setClientsList] = useState<any>([]);
    const [usersList, setUsersList] = useState<any>([]);
    const [form] = Form.useForm();

    const navigate = useNavigate();

    const tokenExpired = () => {
        notification.warning({
            message: 'Access Failed',
            description: 'Expire or invalid credentials. Login again.',
            showProgress: true,
            pauseOnHover: true,
        });
        localStorage.removeItem('tokens');
        localStorage.removeItem('users');
        navigate('/login');
    };

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/deliveries');
        }
        if (error.response?.status === 401) {
            tokenExpired();
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }

    useEffect(() => {
        const fetchDeliveryDetails = async () => {
            try {
                const response = await axios.get(`${url}/deliveries/${id}`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                await setDelivery(response.data);
                form.setFieldsValue(response.data);
                if (canClients) { await getClientsList(); }
                if (canUser) { await getUsersList(response.data.user); }
            } catch (error: any) {
                console.error('Error fetching data:', error);
                setError(error.message ? error.message : 'Error fetching data');
                notification.error({
                    message: error.message ? error.message : 'Error fetching data',
                    description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                    placement: 'bottomRight',
                });
                errorRedirecciont(error);
            } finally {
                setLoading(false);
            }
        };
        const getClientsList = async () => {
            try {
                const response = await axios.get(`${url}/clients`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setClientsList(response.data);
            } catch (error: any) {
                console.error('Error fetching data:', error);
                notification.error({
                    message: error.message ? error.message : 'Error fetching data',
                    description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                    placement: 'bottomRight',
                });
            }
        }

        const getUsersList = async (user: any) => {
            try {
                const response = await axios.get(`${url}/users-out-delivery`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                if (user) {
                    setUsersList([user, ...response.data]);
                } else {
                    setUsersList(response.data);
                }
            } catch (error: any) {
                console.error('Error fetching data:', error);
                notification.error({
                    message: error.message ? error.message : 'Error fetching data',
                    description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                    placement: 'bottomRight',
                });
            }
        }

        fetchDeliveryDetails();

    }, [id]);

    const onFinish = async (values: any) => {
        try {
            const response = await axios.put(`${url}/deliveries/${id}`, values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            notification.success({
                message: 'Delivery updated successfully',
                description: 'The delivery ' + response.data.name + ' was updated successfully',
                placement: 'bottomRight',
            });
        } catch (error: any) {
            console.error('Error updating delivery:', error);
            notification.error({
                message: error.message ? error.message : 'Error updating delivery',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
        }
    };

    if (loading) {
        return (
            <div style={{ padding: 24 }}>
                < Card loading />
            </div>
        );
    }

    return (
        <div style={{ padding: 24 }}>
            {contextHolder}
            <Card title="Delivery detail" style={{ flex: 3, marginRight: 20, boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }} loading={loading}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col span={12}>
                            {canClients && (

                                <Form.Item
                                    label="Client"
                                    name="client_id"
                                    initialValue={delivery?.client_id}
                                >
                                    {clientsList && (
                                        <Select placeholder="Select a client" defaultValue={delivery?.client?.id} >
                                            {clientsList.map((client: any) => (
                                                <Option key={client.id} value={client.id}>{client.name}</Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>)

                            }
                            <Form.Item
                                label="Full Name of the company"
                                name="name"
                                rules={[{ required: true, message: 'Please enter the full name of the company' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Zip code and Address of the delivery place"
                                name="zipcode"
                                rules={[{ required: true, message: 'Please enter the zip code and address of the delivery place' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Tax Id"
                                name="tax_id"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[{ required: true, message: 'Please enter the phone' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Mail"
                                name="email"
                                rules={[
                                    { required: true, message: 'Please enter the email' },
                                    { type: 'email', message: 'Please enter a valid email' }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Main Contact Person"
                                name="contact_main"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Contact person at Warehouse (in case exists)"
                                name="contact_warehouse"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Commodity"
                                name="commodity"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {
                                canUser && (
                                    <Form.Item
                                        label="User"
                                        name="user_id"
                                        initialValue={delivery?.user_id}
                                    >
                                        {
                                            usersList && (
                                                <Select placeholder="Select a user" defaultValue={delivery?.user?.id}  >
                                                    {usersList.map((user: any) => (
                                                        <Option key={user.id} value={user.id}>{user.name}</Option>
                                                    ))}
                                                </Select>
                                            )
                                        }
                                    </Form.Item>

                                )
                            }


                            <Form.Item label="Delivery schedules" >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="schedules_start"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <TimePicker format="HH:mm" placeholder="Start Time" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="schedules_end"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <TimePicker format="HH:mm" placeholder="End Time" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                            <Form.Item
                                label="Is it Stackable"
                                name="stackable"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is this a residential delivery place"
                                name="redidential_place"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is a lift or Dolly required for delivery"
                                name="lift_dolly"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is open daily from Monday to Friday?"
                                name="open_daily"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Is it mandatory to do an appointment?"
                                name="mandatory"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                label="Besides the POD, is it necessary to get a different document?"
                                name="beside_pod"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Update Delivery
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default DeliveryDetails;