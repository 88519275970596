import React, { useEffect } from 'react';
import * as echarts from 'echarts';

interface DonutChartProps {
  id: string;
}

const DonutChart: React.FC<DonutChartProps> = ({ id }) => {
  useEffect(() => {
    const chartDom = document.getElementById(id)!;
    const myChart = echarts.init(chartDom);
    const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');
    const option = {
      title: {
        top: 10,  
        left: 10,
        text: 'Shipments status',
        color: isDarkMode ? '#FF0000' : '#000000'
      },
      color: [ '#00a2ae','#87d068','#ffbf00', '#ff0000'],
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: 'auto',
        left: 10,
        bottom : 10,
        orient: 'vertical',
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10
          },
          padAngle: 5,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: 117, name: 'In transit' },
            { value: 1170, name: 'Delivered' },
            { value: 100, name: 'Incidens' },
            { value: 100, name: 'Cancel' },
            { value: 300, name: 'Created' }
          ]
        }
      ]
    };

    myChart.setOption(option);

    // Cleanup function to dispose of the chart instance when the component unmounts
    return () => {
      myChart.dispose();
    };
  }, [id]);

  return <div id={id} style={{ width: 'auto', height: '400px' }}></div>;
};

export default DonutChart;