import React, { useEffect, useState } from 'react';
import { Select, Input, Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import hasPermission from '../../Hooks/hasPermission';
import axios from 'axios';
import { set } from 'date-fns';

const { Option } = Select;
const { Search } = Input;

interface UserFiltersProps {
    onSearch: (value: string) => void; 
    onCreate: () => void;
}

const UserFilters: React.FC<UserFiltersProps> = ({ onSearch, onCreate }) => { 
    const user = JSON.parse(localStorage.getItem('users') || '[]')[0]; 
    const canCreate = hasPermission(user?.permissionsActives, 'CL_CREATE');  
    
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const handler = setTimeout(() => {
            onSearch(searchTerm);
        }, 2000);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }; 

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {canCreate && (<Button
                icon={React.createElement(UserAddOutlined)}
                type="primary"
                style={{ minWidth: 200, flex: 1, marginInline: 10 }}
                onClick={onCreate} 
            >
                Create User
            </Button>)}
 

            <Search
                placeholder="Search"
                onChange={handleSearchChange}
                style={{ flex: 2, marginInline: 10 }} 
            />
        </div>
    );
};

export default UserFilters;