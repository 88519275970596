import React from 'react';
import { Card, Row, Col, notification } from 'antd';
import UserTable from './UserTable';
import UserFilters from './UserFilters';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { set } from 'date-fns';

const Users: React.FC = () => {
    const url = process.env.REACT_APP_API_URL || 'http://localhost:3306/api';
    const [users, setUsers] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    const navigate = useNavigate();

    const [paramSerach, setParamSearch] = React.useState<null|string>(null); 

    const tokenExpired = () => {
        notification.warning({
            message: 'Access Failed',
            description: 'Expire or invalid credentials. Login again.',
            showProgress: true,
            pauseOnHover: true,
        });
        localStorage.removeItem('tokens');
        localStorage.removeItem('users');
        navigate('/login');
    };

    const errorRedirecciont = (error: any) => {
        if (error.response?.status === 404) {
            navigate('/');
        }
        if (error.response?.status === 401) {
            tokenExpired();
        }
        if (error.response?.status === 403) {
            navigate('/');
        }
    }
    const fetchUsers = async () => { 
        setLoading(true);
        try { 
            const response = await axios.get(`${url}/users`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    params: {search: paramSerach}
                }
            );
            setUsers(response.data);
        } catch (error: any) {
            console.error('Error fetching data:', error);
            notification.error({
                message: error.message ? error.message : 'Error fetching data',
                description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
                placement: 'bottomRight',
            });
            errorRedirecciont(error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchUsers();
    }, [paramSerach]);


    return (
        <div style={{ padding: '24px' }}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Card style={{ boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)', minWidth: '50%' }}>
                            <UserFilters onSearch={function (value: string): void {
                                setParamSearch(value); 
                            }} onCreate={function (): void {
                                navigate('/user-create');
                            }} 

                            />
                        </Card>

                    </div>
                </Col>
                <Col span={24}>
                    <Card loading={loading} style={{ boxShadow: '10px 20px 20px rgba(125, 125,125, 0.25)' }}>
                        <UserTable data={users} onEdit={function (key: string): void {
                            console.log(key);
                            navigate('/user-detail/' + key);
                        }} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Users;