export const lightTheme = {
    token: {
        colorPrimary: '#00827e',
        borderRadius: 2,
        colorBgContainer: '#ffffff',
        colorText: '#000000',
        colorSecondary: '#ff9e18',    
        colorBgBase: '#ebebeb', 
        colorTextBase: '#000000',  
        card: {
            backgroundColor: '#f5f5f5',
            borderColor: '#d9d9d9',
            textColor: '#000000',
        },
        table: {
            headerBackgroundColor: '#fafafa',
            headerTextColor: '#000000',
            rowBackgroundColor: '#ffffff',
            rowTextColor: '#000000',
        },
        background: {
            default: '#ffffff',
            secondary: '#f0f0f0',
        },
        navbar: {
            backgroundColor: '#ffffff',
            textColor: '#000000',
        },
        datePicker: {
            backgroundColor: '#ffffff',
            borderColor: '#d9d9d9',
            textColor: '#000000',
            selectedBackgroundColor: '#00827e',
            selectedTextColor: '#ffffff',
        },
        select: {
            backgroundColor: '#ffffff',
            borderColor: '#d9d9d9',
            textColor: '#000000',
            selectedBackgroundColor: '#00827e',
            selectedTextColor: '#ffffff',
        },
        search: {
            backgroundColor: '#ffffff',
            borderColor: '#d9d9d9',
            textColor: '#000000',
            placeholderColor: '#a9a9a9',
        },
    },
};

export const darkTheme = {
    token: {
        colorPrimary: '#00827e',
        borderRadius: 2,
        colorBgContainer: '#1f1f1f',
        colorText: '#eeeeee',
        colorSecondary: '#ff9e18',      
        colorBgBase: '#000000', 
        colorTextBase: '#eeeeee',
        card: {
            backgroundColor: '#2a2a2a',
            borderColor: '#cccccc',
            textColor: '#eeeeee',
        },
        table: {
            headerBackgroundColor: '#cccccc',
            headerTextColor: '#eeeeee',
            rowBackgroundColor: '#2a2a2a',
            rowTextColor: '#eeeeee',
        },
        background: {
            default: '#333333',
            secondary: '#2a2a2a',
        },
        navbar: {
            backgroundColor: '#1f1f1f',
            textColor: '#eeeeee',
        },
        datePicker: {
            backgroundColor: '#2a2a2a',
            borderColor: '#cccccc',
            textColor: '#eeeeee',
            selectedBackgroundColor: '#00827e',
            selectedTextColor: '#eeeeee',
        },
        select: {
            backgroundColor: '#2a2a2a',
            borderColor: '#000000',
            textColor: '#eeeeee',
            selectedBackgroundColor: '#00827e',
            selectedTextColor: '#eeeeee',
        },
        search: {
            backgroundColor: '#2a2a2a',
            borderColor: '#cccccc',
            textColor: '#eeeeee',
            placeholderColor: '#a9a9a9',
        },
    },
};