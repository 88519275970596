// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ClientList.css */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
    filter: drop-shadow(-10px -20px -20px rgba(135, 208, 104, 0));
  }
  to {
    opacity: 1;
    transform: translateX(0);
    filter: drop-shadow(10px 20px 20px rgba(135, 208, 104, 0.3));
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
    filter: drop-shadow(10px 20px 20px rgba(135, 208, 104, 0.3));
  }
  to {
    opacity: 0;
    transform: translateX(-20px);
    filter: drop-shadow(-10px -20px -20px rgba(135, 208, 104, 0));
  }
}

.card-appear {
  animation: fadeIn 0.2s ease-in-out forwards;
}

.card-disappear {
  animation: fadeOut 0.5s ease-in-out forwards;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Clients/ClientList.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE;IACE,UAAU;IACV,4BAA4B;IAC5B,6DAA6D;EAC/D;EACA;IACE,UAAU;IACV,wBAAwB;IACxB,4DAA4D;EAC9D;AACF;;AAEA;EACE;IACE,UAAU;IACV,wBAAwB;IACxB,4DAA4D;EAC9D;EACA;IACE,UAAU;IACV,4BAA4B;IAC5B,6DAA6D;EAC/D;AACF;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":["/* ClientList.css */\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n    transform: translateX(-20px);\n    filter: drop-shadow(-10px -20px -20px rgba(135, 208, 104, 0));\n  }\n  to {\n    opacity: 1;\n    transform: translateX(0);\n    filter: drop-shadow(10px 20px 20px rgba(135, 208, 104, 0.3));\n  }\n}\n\n@keyframes fadeOut {\n  from {\n    opacity: 1;\n    transform: translateX(0);\n    filter: drop-shadow(10px 20px 20px rgba(135, 208, 104, 0.3));\n  }\n  to {\n    opacity: 0;\n    transform: translateX(-20px);\n    filter: drop-shadow(-10px -20px -20px rgba(135, 208, 104, 0));\n  }\n}\n\n.card-appear {\n  animation: fadeIn 0.2s ease-in-out forwards;\n}\n\n.card-disappear {\n  animation: fadeOut 0.5s ease-in-out forwards;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
