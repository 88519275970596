import React, { useEffect, useState } from 'react';
import { Alert, Card } from 'antd';
import StatusCardsCounter from './Reports/StatusCardsCounter';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import DonutChart from './Dashboard/Componets/DonutChar';
import ComplexDonut from './Dashboard/Componets/ComplexDonut';
import DailyChanges from './Dashboard/Componets/DailyChanges';
import MixBarLines from './Dashboard/Componets/MixBarLines'; 
const Home: React.FC = () => {
  const url = process.env.REACT_APP_API_URL;

  const initialStatusData = [
    { status: 'Total', count: 0, color: '', selected: false },
    { status: 'Quotes Received', count: 0, color: '#ffbf00', selected: false },
    { status: 'Unassigned', count: 0, color: '#ffbf00', selected: false },
    { status: "Assigned", count: 0, color: '#00a2ae', selected: false },
    { status: 'In Transit', count: 0, color: '#00a2ae', selected: false },
    { status: 'Completed', count: 0, color: '#87d068', selected: false },
    { status: 'Canceled', count: 0, color: '#ff0000', selected: false },
  ];


  const [statusData, setStatusData] = useState(initialStatusData);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getStatusCards();
    setLoading(false);
  }, []);

  const tokenExpired = () => {
    notification.warning({
      message: 'Access Failed',
      description: 'Expire or invalid credentials. Login again.',
      showProgress: true,
      pauseOnHover: true,
    });
    localStorage.removeItem('tokens');
    localStorage.removeItem('users');
    navigate('/login');
  };

  const getStatusCards = async () => {
    const token = JSON.parse(localStorage.getItem('tokens') || '[]')[0];
    try {
      const response = await axios.get(`${url}/status-loads-cards`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }); 
      const updatedStatusData = statusData.map(item => {
        const matchingStatus = response.data.find((statusItem: any) => statusItem.Estatus === item.status);
        return matchingStatus ? { ...item, count: matchingStatus.count } : { ...item, count: 0 };
      });

      if (updatedStatusData.length > 0) {
        const firstItem = updatedStatusData[0];
        for (let i = 1; i < updatedStatusData.length; i++) {
          firstItem.count += updatedStatusData[i].count;
        }
      }

      setStatusData(updatedStatusData);
    } catch (error: any) {
      if (error.response?.status === 401) {
        tokenExpired();
      } else if (error.response) {
        notification.error({
          message: error.message ? error.message : 'Error fetching data',
          description: error.response?.data?.message ? error.response?.data?.message : 'Something went wrong',
          placement: 'bottomRight',
      });
      }
      console.error('Error fetching status data:', error);
    }
  };
  const handleCardClick = async (status: string) => {
    console.log('Hola ! :D');
  }; 

  return (
    <>
      <StatusCardsCounter statusData={statusData} handleCardClick={handleCardClick} loading={loading} />  
        <Alert message="This dashboard is a design demo, the information displayed is not real !" type="warning" />
      <br />
      <Card style={{marginInline:'20PX', boxShadow:'10px 20px 20px  rgba(125, 125,125, 0.25)'}}>
      <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ flex:1, maxWidth: '90%'}}>
          <DailyChanges /> 
        </div>
      </div>

      </Card>
      <div style={{ display: 'flex'}}>
        <div style={{ flex:4}}>
          <Card style={{marginBlock: '20px', marginLeft:'20px', boxShadow:'10px 20px 20px  rgba(125, 125,125, 0.25)'}}> 
            <div style={{ flex:1 , maxWidth:'95%'}}> 
              <ComplexDonut />   
            </div>
          </Card>
        </div>
        <div style={{ flex: 6 }}>
          <Card style={{marginBlock: '20px', marginInline:'20px', maxWidth:'96%', boxShadow:'10px 20px 20px  rgba(125, 125,125, 0.25)'}}>
          <div style={{ display: 'flex'}}>
              <div style={{flex:1}}>
              <DonutChart id={'donut-1'}/>   
                
              </div>
              <div style={{flex:1}}>
              <DonutChart id={'donut-2'}/>   
                
              </div>

            </div>
          </Card>
          <Card style={{marginBlock: '20px', marginInline:'20px', maxWidth:'96%', boxShadow:'10px 20px 20px  rgba(125, 125,125, 0.25)'}}>
            <MixBarLines />  
          </Card>
        </div> 
      </div>
      {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Result
          status="warning"
          title="This page is not available yet"
          subTitle="Sorry, we are working on it."
          extra={
            <Button type="primary" href="/reports">
              Go to Loads
            </Button>
          }
        />
      </div> */}
    </>
  );
};

export default Home;