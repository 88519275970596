import React, { useEffect } from 'react';
import * as echarts from 'echarts';

const DailyChanges: React.FC = () => {
  useEffect(() => {
    const chartDom = document.getElementById('dailyChanges')!;
    const myChart = echarts.init(chartDom);

    function getVirtualData(year: string) {
      const date = +echarts.time.parse(year + '-01-01');
      const end = +echarts.time.parse(+year + 1 + '-01-01');
      const dayTime = 3600 * 24 * 1000;
      const data = [];
      for (let time = date; time < end; time += dayTime) {
        data.push([
          echarts.time.format(time, '{yyyy}-{MM}-{dd}', false),
          Math.floor(Math.random() * 10000)
        ]);
      }
      return data;
    }

    const isDarkMode = JSON.parse(localStorage.getItem('theme') || 'false');

    const option = {
      color: ['#00827e', '#04c0b9', '#00d9d1', '#00fff6'],
      title: {
        top: 30,
        left: 'center',
        text: 'Daily shipments',
        color: isDarkMode ? '#FF0000' : '#000000'
      },
      tooltip: {},
      visualMap: {
        min: 0,
        max: 10000,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        top: 65,
        color: [
          "#00827e",
          "#04c0b9",
          "#00d9d1",
          "#00fff6"
        ]
      },
      legend: {
        orient: 'vertical',
        bottom: 0,
      },
      calendar: {
        top: 120,
        left: 30,
        right: 30,
        cellSize: ['auto', 15],
        range: '2024',
        itemStyle: {
          borderWidth: .7,
          areaColor: "rgba(10,215,0,0.8)",
          borderColor: "#444444DD",
        },
        yearLabel: { show: true },
      },
      series: {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: getVirtualData('2024')
      },

    };

    myChart.setOption(option);

    // Cleanup function to dispose of the chart instance when the component unmounts
    return () => {
      myChart.dispose();
    };
  }, []);

  return <div style={{ maxWidth: '100%' }}>
    <div id="dailyChanges" style={{ width: 'auto', height: '250px' }}></div>
  </div>
};

export default DailyChanges;